import type { FC } from 'react';

interface AboutPageProps { }

const AboutPage: FC<AboutPageProps> = ({ }) => {
    return (
        <section className="bg-gray-50" id='page'>
            <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                <h1 className='text-4xl font-bold mt-2 mb-2'>Tentang Jaraya</h1>

                <p>
                    Selamat datang di <strong>Jaraya</strong>, platform digital inovatif yang dirancang untuk mengoptimalkan dan mendigitalisasi proses bisnis Usaha Mikro, Kecil, dan Menengah (<strong>UMKM</strong>). Kami memahami tantangan yang dihadapi oleh UMKM dalam mengelola operasi sehari-hari dan memaksimalkan pertumbuhan mereka. Oleh karena itu, <strong>Jaraya</strong> hadir untuk menyediakan solusi terpadu yang efisien dan mudah digunakan, membantu bisnis Anda berkembang dengan lebih cepat dan berkelanjutan.
                </p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Visi Kami</h2>
                <p>
                    Visi kami adalah menjadi mitra terpercaya bagi UMKM dalam perjalanan mereka menuju transformasi digital. Kami berkomitmen untuk memberdayakan bisnis dengan alat-alat yang inovatif, memungkinkan mereka untuk bersaing di pasar yang semakin kompetitif dengan keunggulan teknologi.
                </p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Misi Kami</h2>
                <p>
                    Misi kami adalah menyediakan solusi digital yang intuitif dan terjangkau untuk membantu UMKM dalam mengelola keuangan, operasi, dan jaringan bisnis mereka. Kami berusaha menciptakan ekosistem yang mendukung pertumbuhan dan keberhasilan jangka panjang.
                </p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Fitur Utama Jaraya</h2>
                <p><strong>Jaraya</strong> menawarkan serangkaian fitur canggih yang dirancang untuk memenuhi kebutuhan bisnis UMKM:</p>
                <ul>
                    <li><strong>Pencatatan Keuangan yang Terintegrasi:</strong> Jaraya menyediakan alat pencatatan keuangan yang mudah digunakan untuk mengelola pendapatan, pengeluaran, penjualan, dan pembelian. Fitur ini membantu bisnis Anda menjaga catatan keuangan yang akurat dan up-to-date, memudahkan pelaporan dan analisis keuangan.</li>
                    <li><strong>Hub Interaksi Bisnis:</strong> Jaraya berfungsi sebagai hub interaksi bisnis yang menghubungkan berbagai pengguna dalam ekosistem. Ini memungkinkan UMKM untuk berinteraksi, berkolaborasi, dan melakukan transaksi dengan mitra bisnis lainnya secara efisien, meningkatkan peluang pertumbuhan dan sinergi bisnis.</li>
                    <li><strong>Ekosistem Bisnis yang Terintegrasi:</strong> Jaraya berkembang menjadi ekosistem bisnis yang dinamis, menyediakan platform bagi pengguna untuk mengakses berbagai layanan tambahan, seperti akses ke pembiayaan, pasar digital, dan alat pemasaran. Ini membantu UMKM meningkatkan visibilitas dan menjangkau lebih banyak pelanggan potensial.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Manfaat Menggunakan Jaraya</h2>
                <ul>
                    <li><strong>Efisiensi Operasional:</strong> Dengan Jaraya, Anda dapat mengotomatisasi dan merampingkan proses bisnis, mengurangi beban administratif, dan fokus pada strategi pertumbuhan.</li>
                    <li><strong>Keakuratan Keuangan:</strong> Fitur pencatatan keuangan kami memastikan keakuratan dan transparansi dalam pengelolaan keuangan, membantu Anda mengambil keputusan yang lebih baik dan tepat waktu.</li>
                    <li><strong>Kolaborasi dan Jaringan:</strong> Hub interaksi bisnis Jaraya memungkinkan Anda untuk membangun jaringan yang kuat dengan mitra bisnis lainnya, membuka peluang kolaborasi dan perdagangan yang lebih luas.</li>
                    <li><strong>Akses ke Layanan Tambahan:</strong> Dengan ekosistem yang terintegrasi, Anda dapat dengan mudah mengakses layanan tambahan seperti pembiayaan dan pasar digital, mendukung pertumbuhan bisnis Anda.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Dukungan Pemerintah dan Pemberdayaan UMKM</h2>
                <p>
                    Kami bangga bekerja sama dengan berbagai inisiatif pemerintah yang mendukung digitalisasi UMKM. Dengan dukungan ini, <strong>Jaraya</strong> berperan aktif dalam membantu UMKM meningkatkan daya saing dan mencapai keberhasilan di era digital.
                </p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Ayo Mulai Bersama Jaraya</h2>
                <p>
                    Bergabunglah dengan <strong>Jaraya</strong> dan mulailah perjalanan transformasi digital Anda hari ini. Kami siap mendukung Anda dengan solusi yang komprehensif dan inovatif untuk memastikan bahwa bisnis Anda tidak hanya bertahan, tetapi juga berkembang di pasar yang kompetitif.
                </p>
                <p>
                    Untuk informasi lebih lanjut, hubungi tim kami di <a href="mailto:support@jaraya.com">support@jaraya.com</a>.
                </p>

            </div>
        </section>
    );
}
export default AboutPage;