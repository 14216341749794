import { useState, type FC } from 'react';
import { RiArrowUpLine } from 'react-icons/ri';
import { HiChevronUp, HiChevronDown } from "react-icons/hi2";

interface FaqProps { }

interface FaqItem {
    id: string
    ask: string
    answer: string
}
const Faq: FC<FaqProps> = ({ }) => {
    const [expandeds, setExpandeds] = useState(["1"]);
    const faqs: FaqItem[] = [
        { id: "1", ask: "Apa itu Jaraya?", answer: "Jaraya adalah sistem manajemen bisnis dengan fitur lengkap yang siap membantu usaha Anda untuk terus berkembang. Jaraya siap menjadi bagian dari cerita kesuksesan bisnis Anda" },
        { id: "2", ask: "Mengapa Jaraya menjadi pilihan terbaik untuk manajemen bisnis saya?", answer: "Jika dibandingkan dengan aplikasi manajemen bisnis lainnya, Jaraya tentu menjadi pilihan terbaik. Karena Jaraya memberikan fitur dan dukungan yang lebih kaya dan tentunya lebih mudah digunakan. Semua bisa didapatkan dengan biaya berlangganan yang jauh lebih kompetitif." },
        
        { id: "3", ask: "Bagaimana Jaraya dapat membantu bisnis tumbuh dan berkembang?", answer: "Aplikasi manajemen bisnis Jaraya menyediakan berbagai fitur-fitur reliabel untuk memastikan usaha Anda bisa terus berjalan dengan efektif dan efisien dan minim kesalahan. Mulai dari fitur-fitur seperti sistem pembukuan yang canggih, point-of-sale (POS) untuk penjualan, manajemen inventaris, sistem payroll, serta berbagai laporan keuangan yang terperinci. <br/> <br/>Semua fitur Jaraya yang saling terkoneksi tentu akan membantu Anda meningkatkan penjualan. Selain itu Jaraya pun bisa membantu Anda dalam pengambilan keputusan bisnis lewat sistem dashboard yang menyuguhkan ringkasan data bisnis yang praktis dan lengkap. Semua laporan usaha yang disuguhkan Jaraya juga merupakan faktor penunjang penting bagi calon investor atau lembaga penyalur bantuan modal usaha yang berniat untuk membantu usaha Anda agar bisa naik ke level yang lebih tinggi" },
        { id: "4", ask: "Apakah Jaraya mendukung multi-user dan company?", answer: "Ya, Jaraya mendukung multi-user dan company. Hal ini tentu akan sangat membantu Anda dalam mengelola beberapa perusahaan yang Anda miliki, tanpa ada biaya tambahan" },
        { id: "5", ask: "Apa yang terjadi setelah masa trial Jaraya berakhir?", answer: "Pada saat masa trial berakhir, Anda masih tetap bisa menggunakan aplikasi Jaraya. Hanya saja ada beberapa fitur yang akan dibatasi penggunaannya." },
        { id: "6", ask: "Apakah Jaraya aman digunakan untuk bisnis saya?", answer: "Keamanan data pengguna adalah prioritas utama kami. Jaraya dilengkapi dengan langkah-langkah keamanan yang ketat untuk melindungi data sensitif bisnis Anda." },
    ]
    return (
        <section className="bg-white mt-16">
            <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6 ">
                <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl ">
                    Frequently asked questions
                </h2>
                <div className="max-w-screen-md mx-auto">
                    <div
                        id="accordion-flush"
                        data-accordion="collapse"
                        data-active-classes="bg-white text-gray-900 "
                        data-inactive-classes="text-gray-500 "
                    >
                        {faqs.map(e => <div key={e.id}>
                            <h3 id={`accordion-flush-heading-${e.id}`}>
                                <button
                                    type="button"
                                    className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-900 bg-white border-b border-gray-200  "
                                    data-accordion-target="#accordion-flush-body-1"
                                    aria-expanded="true"
                                    aria-controls="accordion-flush-body-1"
                                >
                                    <span>{e.ask}</span>
                                    <span className=' cursor-pointer' onClick={() => {
                                        if (expandeds.includes(e.id)) {
                                            setExpandeds([...expandeds.filter(s => !e.id)])
                                        } else {
                                            setExpandeds([...expandeds, e.id])
                                        }
                                    }}>
                                       {expandeds.includes(e.id) ? <HiChevronUp /> : <HiChevronDown />} 
                                    </span>
                                </button>
                            </h3>
                            <div
                                id="accordion-flush-body-1"
                                className={!expandeds.includes(e.id) ? `hidden` : ''}
                                aria-labelledby={`accordion-flush-heading-${e.id}`}
                            >
                                <div className="py-5 border-b border-gray-200 ">
                                    <p className="mb-2 text-gray-500 " dangerouslySetInnerHTML={{ __html: e.answer }}></p>
                                    
                                </div>
                            </div>
                        </div>)}


                    </div>
                </div>
            </div>
        </section>
    );
}
export default Faq;