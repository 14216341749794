import type { FC } from 'react';

interface AdvantageProps { }

const Advantage: FC<AdvantageProps> = ({ }) => {
  return (
    <section className="bg-gray-50" id='advance'>
      <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
        {/* Row */}
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <div className="text-gray-500 sm:text-lg ">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">
              Jaraya: Keunggulan yang Membuat Perbedaan
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Transformasi Pengelolaan Keuangan UMKM dengan Jaraya: Fitur-Fitur Canggih yang Menyederhanakan Setiap Langkah Pengelolaan Finansial Anda
            </p>
            {/* List */}
            <ul
              role="list"
              className="pt-8 space-y-5 border-t border-gray-200 my-7 "
            >
              <li className="flex space-x-3">
                {/* Icon */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-purple-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className='-mt-1'>
                  <span className="text-base font-medium leading-tight text-gray-900 ">
                    Multi-Company
                  </span>
                  <div>
                    <p>Kelola Banyak Perusahaan dalam Satu Akun</p>
                    <p className='text-sm'>Dengan fitur Multi-Company, Anda dapat mengelola keuangan dari beberapa perusahaan atau unit bisnis Anda tanpa perlu akun terpisah. Setiap perusahaan memiliki pembukuan dan laporan keuangannya sendiri, namun tetap terintegrasi dalam satu platform Jaraya. Mudah diakses, diatur, dan diawasi, memastikan kontrol penuh atas seluruh operasi bisnis Anda.</p>
                  </div>
                </div>
              </li>
              <li className="flex space-x-3">
                {/* Icon */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-purple-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className='-mt-1'>
                  <span className="text-base font-medium leading-tight text-gray-900 ">
                    Multi-User
                  </span>
                  <div>
                    <p>Kolaborasi Tanpa Batas dengan Banyak Pengguna</p>
                    <p className='text-sm'>Fitur Multi-User memungkinkan Anda untuk menambahkan dan mengelola banyak pengguna dalam satu akun perusahaan. Setiap pengguna dapat diberikan akses dan izin yang berbeda, sesuai dengan peran mereka. Ini mempermudah kolaborasi tim dalam menangani tugas keuangan, seperti pencatatan transaksi, persetujuan, dan pembuatan laporan, tanpa khawatir tentang keamanan atau kerahasiaan data.</p>
                  </div>
                </div>
              </li>


            </ul>

          </div>
          <img
            className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
            src="/images/3rd Slide.png"
            alt="dashboard feature image"
          />
        </div>
        {/* Row */}
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <img
            className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
            src="/images/4th Slide.png"
            alt="feature image 2"
          />
          <div className="text-gray-500 sm:text-lg ">

            {/* List */}
            <ul
              role="list"
              className="pt-8 space-y-5 border-t border-gray-200 my-7 "
            >
              <li className="flex space-x-3">
                {/* Icon */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-purple-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className='-mt-1'>
                  <span className="text-base font-medium leading-tight text-gray-900 ">
                    Multi-Currency
                  </span>
                  <div>
                    <p>Kemudahan dalam Transaksi Multi-Mata Uang</p>
                    <p className='text-sm'>Dengan dukungan Multi-Currency, Jaraya memudahkan pengelolaan transaksi dalam berbagai mata uang. Anda dapat mencatat dan melacak pendapatan serta pengeluaran dalam mata uang asing, dengan konversi otomatis ke mata uang dasar yang Anda tentukan. Fitur ini sangat ideal untuk UMKM yang beroperasi secara internasional atau memiliki transaksi dengan klien dan pemasok luar negeri.</p>
                  </div>
                </div>
              </li>
              <li className="flex space-x-3">
                {/* Icon */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-purple-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className='-mt-1'>
                  <span className="text-base font-medium leading-tight text-gray-900 ">
                    Multi-Warehouse
                  </span>
                  <div>
                    <p>Pengelolaan Gudang yang Efisien untuk Banyak Lokasi</p>
                    <p className='text-sm'>Fitur Multi-Warehouse memungkinkan Anda mengelola stok di berbagai gudang atau lokasi penyimpanan. Anda bisa melacak pergerakan barang antara gudang, memantau inventaris, dan mengoptimalkan penyimpanan serta distribusi produk Anda. Ini membantu Anda menjaga ketersediaan barang yang optimal di setiap lokasi tanpa perlu biaya tambahan.</p>
                  </div>
                </div>
              </li>
              <li className="flex space-x-3">
                {/* Icon */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-purple-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className='-mt-1'>
                  <span className="text-base font-medium leading-tight text-gray-900 ">
                  Tanpa Batas dan Tanpa Biaya Tambahan
                  </span>
                  <div>
                    <p>Nikmati Kebebasan Penggunaan Tanpa Biaya Tersembunyi</p>
                    <p className='text-sm'>Jaraya menawarkan semua fitur ini tanpa batasan jumlah perusahaan, pengguna, mata uang, atau gudang yang dapat Anda kelola, dan semuanya tanpa biaya tambahan. Tidak ada biaya tersembunyi atau batasan kuota, memungkinkan Anda untuk memperluas bisnis dengan fleksibilitas penuh dan efisiensi biaya.</p>
                  </div>
                </div>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
    </section>
  );
}
export default Advantage;