import type { FC } from 'react';

interface MainProps { }

const Main: FC<MainProps> = ({ }) => {
  return (<section className="bg-white mt-8">
    <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
      <h3 className='text-center text-4xl font-bold text-gray-400'>Perusahaan yang Tumbuh bersama Jaraya</h3>
      <p className="mb-8 font-light lg:text-xl text-center mb-4">Banyak Bisnis yang Telah Memperoleh Manfaat Besar dari Fitur-Fitur Unggulan Jaraya</p>

      <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 ">
        <a href="#" className="flex items-center lg:justify-center">
          <img src="/images/client/ae.png" alt="" />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
          <img src="/images/client/afs.png" alt="" />
        </a>
        {/* <a href="#" className="flex items-center lg:justify-center">
          <img src="/images/client/es.png" alt="" />
        </a> */}
        <a href="#" className="flex items-center lg:justify-center ">
          <img src="/images/client/ihms.jpeg" alt="" />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
          <img src="/images/client/Logo-Injury Hub.png" alt=""  className='bg-black p-2'/>
        </a>
        <a href="#" className="flex items-center lg:justify-center">
          <img src="/images/client/um.png" alt="" />
        </a>

        <a href="#" className="flex items-center lg:justify-center">
          <img src="/images/client/rb.png" alt="" />
        </a>


      </div>
      <p className="mb-8 font-light lg:text-xl text-center mt-4 text-gray-600">Dan masih banyak klien bahagia lainnya yang merasakan manfaat Jaraya setiap hari</p>
    </div>
  </section>);
}
export default Main;