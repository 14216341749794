import type { FC } from 'react';
import { RiCustomerService2Line, RiHandHeartLine, RiLock2Line } from 'react-icons/ri';

interface TrustedProps { }

const Trusted: FC<TrustedProps> = ({ }) => {
    return (
        <section className="bg-white " id='solution'>
            <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
                <div className="col-span-2 mb-8">
                    <p className="text-lg font-medium text-purple-600 ">
                        Jaraya: Solusi yang Berbeda
                    </p>
                    <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl ">
                        Apa yang Membuat Jaraya Lebih Efisien dan Efektif Dibandingkan dengan Produk Sejenis?
                    </h2>
                    <p className="font-light text-gray-500 sm:text-xl ">
                        Temukan Keunggulan Jaraya yang Membuatnya Pilihan Utama untuk Pengelolaan Keuangan Bisnis Anda
                    </p>
                    <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 ">
                        <div>
                            <a
                                target='_blank'
                                href="https://help.jaraya.id"
                                className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 "
                            >
                                Jelajahi Pusat Bantuan Jaraya disini!
                                <svg
                                    className="w-5 h-5 ml-1"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                        </div>
                        {/* <div>
                            <a
                                href="#"
                                className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 "
                            >
                                Visit the Trust Center
                                <svg
                                    className="w-5 h-5 ml-1"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                    <div>
                        <RiHandHeartLine className='text-violet-600' size={48} />
                        <h3 className="mb-2 text-2xl font-bold ">
                            #LebihMudah Digunakan
                        </h3>
                        <p className="font-light text-gray-500 ">
                            Jaraya bisa membuat siapa pun, kapan pun dan di mana pun untuk mengelola bisnis jadi #LebihMudah, bahkan bagi mereka yang tidak terbiasa dengan dunia akuntansi.
                        </p>
                    </div>
                    <div>
                        <RiCustomerService2Line className='text-violet-600' size={48} />
                        <h3 className="mb-2 text-2xl font-bold ">
                            Customer Service Responsif
                        </h3>
                        <p className="font-light text-gray-500 ">
                            Jaraya menyediakan Customer Service Responsif yang siap membantu dengan segala pertanyaan dan kendala Anda melalui pertemuan online.
                        </p>
                    </div>
                    <div>
                        <RiLock2Line className='text-violet-600' size={48} />
                        <h3 className="mb-2 text-2xl font-bold ">
                        Legalitas dan Keamanan
                        </h3>
                        <p className="font-light text-gray-500 ">
                        Jaraya sudah berbadan hukum dan terdaftar di PSE Kominfo. Data keuanganmu juga terproteksi dengan standar keamanan Open Web Application Security Project (OWASP)
                        </p>
                    </div>
                    <div>
                        <a target='_blank' href="https://pse.kominfo.go.id/tdpse-detail/15206">
                            <img src="/images/pse-terdaftar.png" alt="" className='w-24' />
                        </a>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Trusted;