import type { FC } from 'react';

interface TermPageProps { }

const TermPage: FC<TermPageProps> = ({ }) => {
    return (
        <section className="bg-gray-50" id='page'>
            <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                <h1 className='text-4xl font-bold mt-2 mb-2'>Syarat dan Ketentuan Penggunaan Aplikasi Jaraya</h1>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Terakhir diperbarui: 30/06/2024</h2>

                <h3 className='text-xl font-bold mt-2 mb-2'>1. Penerimaan Syarat dan Ketentuan</h3>
                <p>Dengan menggunakan aplikasi Jaraya, Anda secara otomatis setuju untuk terikat dengan syarat dan ketentuan yang tercantum di bawah ini. Jika Anda tidak menyetujui syarat ini, harap berhenti menggunakan aplikasi ini.</p>

                <h3 className='text-xl font-bold mt-2 mb-2'>2. Deskripsi Layanan</h3>
                <p>Aplikasi Jaraya adalah platform digital yang dirancang untuk membantu pengguna dalam mengelola keuangan bisnis mereka. Fitur utama termasuk manajemen transaksi, pelacakan multi company, dan multi currency.</p>

                <h3 className='text-xl font-bold mt-2 mb-2'>3. Pendaftaran Akun</h3>
                <ul>
                    <li>Untuk menggunakan layanan Jaraya, Anda harus mendaftar dan membuat akun pengguna. Informasi yang Anda berikan selama proses pendaftaran harus akurat dan lengkap.</li>
                    <li>Anda bertanggung jawab atas keamanan akun Anda dan informasi login Anda. Anda harus segera memberitahukan kami tentang setiap penggunaan yang tidak sah dari akun Anda.</li>
                </ul>

                <h3 className='text-xl font-bold mt-2 mb-2'>4. Penggunaan Aplikasi</h3>
                <ul>
                    <li>Anda setuju untuk menggunakan aplikasi ini hanya untuk tujuan yang sah dan sesuai dengan hukum yang berlaku.</li>
                    <li>Anda tidak boleh menggunakan aplikasi ini untuk tujuan yang melanggar hukum atau merugikan pihak lain, termasuk melakukan tindakan yang dapat merusak atau mengganggu aplikasi atau layanan kami.</li>
                </ul>

                <h3 className='text-xl font-bold mt-2 mb-2'>5. Pengumpulan dan Penggunaan Informasi</h3>
                <ul>
                    <li>Kami dapat mengumpulkan informasi pribadi Anda sesuai dengan Kebijakan Privasi kami untuk tujuan yang dijelaskan di sana, termasuk namun tidak terbatas pada pemrosesan transaksi, administrasi akun, dan komunikasi dengan Anda.</li>
                    <li>Dengan menggunakan aplikasi ini, Anda menyetujui pengumpulan, penggunaan, dan pengungkapan informasi Anda sesuai dengan Kebijakan Privasi kami.</li>
                </ul>

                <h3 className='text-xl font-bold mt-2 mb-2'>6. Perubahan pada Syarat dan Ketentuan</h3>
                <ul>
                    <li>Kami dapat memperbarui Syarat dan Ketentuan ini dari waktu ke waktu tanpa pemberitahuan sebelumnya. Perubahan akan mulai berlaku pada tanggal diperbarui yang tercantum di bagian atas halaman ini.</li>
                    <li>Penggunaan berkelanjutan Anda atas aplikasi ini setelah perubahan berarti Anda menerima dan menyetujui Syarat dan Ketentuan yang diperbarui.</li>
                </ul>

                <h3 className='text-xl font-bold mt-2 mb-2'>7. Pembatasan Tanggung Jawab</h3>
                <ul>
                    <li>Kami tidak bertanggung jawab atas kerugian atau kerusakan yang timbul dari penggunaan atau ketidakmampuan menggunakan aplikasi ini, termasuk namun tidak terbatas pada kerugian langsung, tidak langsung, atau konsekuensial.</li>
                    <li>Kami tidak memberikan jaminan terkait dengan ketersediaan, keandalan, atau kinerja aplikasi ini.</li>
                </ul>

                <h3 className='text-xl font-bold mt-2 mb-2'>8. Hukum yang Berlaku</h3>
                <p>Syarat dan Ketentuan ini diatur oleh dan ditafsirkan sesuai dengan hukum yang berlaku di Indonesia. Setiap perselisihan akan diselesaikan secara eksklusif di pengadilan yang berwenang di Indonesia.</p>

                <h3 className='text-xl font-bold mt-2 mb-2'>9. Kontak Kami</h3>
                <p>Jika Anda memiliki pertanyaan atau komentar tentang Syarat dan Ketentuan ini, silakan hubungi kami di <a href="mailto:support@jaraya.id">support@jaraya.id</a>.</p>

                <p>Dengan menggunakan aplikasi Jaraya, Anda setuju untuk terikat dengan Syarat dan Ketentuan ini. Terima kasih telah menggunakan layanan kami.</p>


                <h1 className='text-4xl font-bold mt-2 mb-2'>Kebijakan Penghapusan Akun</h1>

                <h2 className='text-2xl font-bold mt-2 mb-2'>1. Pengenalan</h2>
                <p>Kebijakan ini mengatur prosedur penghapusan akun pengguna di platform Jaraya. Kami menghargai privasi Anda dan berkomitmen untuk menangani data pribadi Anda dengan integritas tinggi. Pengguna memiliki hak untuk menghapus akun mereka kapan saja dengan mengikuti prosedur yang ditetapkan di bawah ini.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>2. Cara Menghapus Akun</h2>
                <p>Untuk menghapus akun Jaraya Anda, silakan ikuti langkah-langkah berikut:</p>
                <ul>
                    <li><strong>Masuk ke Akun Anda:</strong> Login ke akun Jaraya Anda menggunakan kredensial yang terdaftar.</li>
                    <li><strong>Akses Pengaturan Akun:</strong> Navigasikan ke bagian "Halaman Profile" dari menu sidebar.</li>
                    <li><strong>Pilih Opsi Penghapusan Akun:</strong> Di bagian kanan halaman profile, Anda akan menemukan opsi "Penghapusan Akun".</li>
                    <li><strong>Konfirmasi Penghapusan:</strong> Anda akan diminta untuk mengonfirmasi penghapusan akun dengan memasukkan alasan penghapusan akun.</li>
                    <li><strong>Penghapusan Akun:</strong> Setelah konfirmasi diterima, akun Anda akan dihapus secara permanen dari sistem kami.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>3. Keamanan dan Pencegahan Kesalahan</h2>
                <ul>
                    <li><strong>Penghapusan Tidak Sengaja:</strong> Untuk mencegah penghapusan akun pengguna secara tidak sengaja, kami menerapkan proses peninjauan yang memberikan lapisan tambahan untuk memastikan bahwa penghapusan adalah tindakan yang disengaja dan benar-benar diperlukan.</li>
                    <li><strong>Verifikasi Identitas:</strong> Kami memastikan bahwa permintaan penghapusan benar-benar berasal dari pengguna yang sah untuk mencegah tindakan berbahaya oleh pihak ketiga yang tidak berwenang.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>4. Regulasi dan Kepatuhan</h2>
                <ul>
                    <li><strong>Kepatuhan Hukum:</strong> Penghapusan akun dilakukan sesuai dengan peraturan yang berlaku. Kami memastikan bahwa hak untuk dilupakan dan kewajiban penyimpanan data dipenuhi sesuai dengan persyaratan hukum yang berlaku.</li>
                    <li><strong>Audit Trail:</strong> Kami menyediakan catatan lengkap tentang siapa yang meminta penghapusan, kapan, dan mengapa, untuk keperluan audit dan kepatuhan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>5. Integritas Data</h2>
                <ul>
                    <li><strong>Konsistensi Data:</strong> Penghapusan pengguna dapat mempengaruhi integritas data, terutama jika pengguna tersebut memiliki data terkait dalam sistem (misalnya, transaksi, catatan aktivitas). Peninjauan membantu mengidentifikasi dampak potensial dan mengambil tindakan mitigasi jika diperlukan.</li>
                    <li><strong>Sistem Dependensi:</strong> Jika pengguna terlibat dalam proses bisnis atau memiliki hak akses khusus, penghapusan pengguna tanpa peninjauan bisa menyebabkan gangguan pada sistem atau proses yang bergantung pada akses pengguna tersebut.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>6. Penyelesaian Kewajiban</h2>
                <ul>
                    <li><strong>Kewajiban Finansial:</strong> Pengguna harus menyelesaikan kewajiban finansial yang mungkin tertunda, seperti pembayaran atau langganan yang aktif, sebelum akun dihapus.</li>
                    <li><strong>Akses ke Data yang Dibutuhkan:</strong> Pengguna dapat meminta salinan data mereka atau menyelesaikan transaksi yang belum selesai sebelum penghapusan dilakukan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>7. Retensi Data</h2>
                <ul>
                    <li><strong>Kebijakan Retensi:</strong> Kami memiliki kebijakan retensi data yang menentukan berapa lama data pengguna harus disimpan bahkan setelah akun dihapus. Peninjauan memastikan bahwa kebijakan ini dipatuhi.</li>
                    <li><strong>Penyimpanan Arsip:</strong> Kami mengelola data yang mungkin perlu disimpan untuk alasan hukum atau bisnis, seperti log audit atau riwayat transaksi.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>8. Pemberitahuan dan Alternatif</h2>
                <ul>
                    <li><strong>Pemberitahuan Pengguna:</strong> Pengguna akan menerima pemberitahuan mengenai penghapusan yang diusulkan dan diberi waktu untuk mengajukan pertanyaan atau keberatan.</li>
                    <li><strong>Alternatif untuk Penghapusan:</strong> Kami menawarkan opsi seperti deaktivasi akun, yang memungkinkan data pengguna tetap ada di sistem tanpa menghapusnya sepenuhnya, memberikan fleksibilitas jika pengguna ingin mengaktifkan kembali akun mereka di masa depan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>9. Pengelolaan Proses</h2>
                <ul>
                    <li><strong>Proses Formal:</strong> Kami menyediakan proses formal untuk penghapusan akun, memastikan bahwa semua langkah yang relevan diikuti dengan benar, dan memberikan dokumentasi untuk referensi masa depan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>10. Bantuan dan Dukungan</h2>
                <p>Jika Anda memerlukan bantuan lebih lanjut terkait penghapusan akun atau memiliki pertanyaan mengenai kebijakan ini, Anda dapat menghubungi tim dukungan pelanggan kami melalui:</p>
                <ul>
                    <li><strong>Email:</strong> <a href="mailto:support@jaraya.id">support@jaraya.id</a></li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>11. Perubahan Kebijakan</h2>
                <p>Jaraya berhak untuk mengubah Kebijakan Penghapusan Akun ini kapan saja. Kami akan memberitahukan pengguna tentang perubahan yang signifikan melalui email atau pemberitahuan di platform. Kami mendorong Anda untuk meninjau kebijakan ini secara berkala untuk tetap mendapat informasi terbaru.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>12. Tanggal Efektif</h2>
                <p>Kebijakan ini efektif mulai tanggal 05 Juli 2024, dan akan berlaku sampai ada perubahan lebih lanjut.</p>

                <hr />

                <p>Dengan menggunakan layanan Jaraya dan menghapus akun Anda, Anda menyetujui kebijakan ini dan memahami konsekuensi dari penghapusan akun Anda.</p>
            </div>
        </section>
    );
}
export default TermPage;