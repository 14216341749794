import type { FC } from 'react';

interface PriceProps { }

let checklist = <svg
className="flex-shrink-0 w-5 h-5 text-green-500 "
fill="currentColor"
viewBox="0 0 20 20"
xmlns="http://www.w3.org/2000/svg"
>
<path
    fillRule="evenodd"
    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
    clipRule="evenodd"
/>
</svg>

const Price: FC<PriceProps> = ({ }) => {
    return (<section className="bg-white " id='price'>
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">
                Tentukan Pilihan Anda
                </h2>
                <p className="mb-5 font-light text-gray-500 sm:text-xl ">
                Temukan Solusi yang Tepat untuk Keuangan Bisnis Anda.
                </p>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
                {/* Pricing Card */}
                <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow  xl:p-8  ">
                    <h3 className="mb-4 text-2xl font-semibold">Gratis</h3>
                    <p className="font-light text-gray-500 sm:text-lg ">
                        Akses gratis untuk dasar-dasar pengelolaan keuangan UMKM, termasuk pencatatan transaksi, laporan sederhana, dan manajemen inventaris tanpa biaya bulanan.
                    </p>
                    <div className="flex items-baseline justify-center my-8">
                        <span className="mr-2 text-5xl font-extrabold">Rp. 0</span>
                        <span className="text-gray-500 ">/Bulan</span>
                    </div>
                    {/* List */}
                    <ul role="list" className="mb-8 space-y-4 text-left">
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>Tanpa biaya bulanan</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>Invoicing dibatasi</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>Laporan Dasar</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                                Perusahaan: <span className="font-semibold">1 Perusahaan</span>
                            </span>
                        </li>
                        
                    </ul>
                   
                </div>
                {/* Pricing Card */}
                <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow  xl:p-8  ">
                    <h3 className="mb-4 text-2xl font-semibold">Premium</h3>
                    <p className="font-light text-gray-500 sm:text-lg ">
                    Langganan bulanan dengan akses penuh ke semua fitur Jaraya, seperti laporan mendetail, integrasi luas, dan dukungan pelanggan prioritas untuk meningkatkan efisiensi dan akurasi pengelolaan keuangan.
                    </p>
                    <small className='mt-4 text-base text-gray-600 line-through'>Rp. 500.000</small>
                    <div className="flex items-baseline justify-center ">
                        <span className="mr-2 text-5xl font-extrabold">Rp. 300.000</span>
                        <span
                            className="text-gray-500 "

                        >
                            /Bulan
                        </span>
                        
                    </div>
                    <small className='mb-4 text-gray-600'>(Jika berlangganan 1 tahun langsung)</small>
                    {/* List */}
                    <ul role="list" className="mb-8 space-y-4 text-left">
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>Unlimited User</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>Unlimited Company</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                                Multiple Currency
                            </span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                            Unlimited Gudang
                            </span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                            Manajemen Aset
                            </span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                            Manajemen Produk dan Inventori
                            </span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                            Invoicing dan Surat Jalan
                            </span>
                        </li>
                        <li className="flex items-center space-x-3">
                            {/* Icon */}
                            {checklist}
                            <span>
                            Akses POS dengan Unlimited Merchant
                            </span>
                        </li>
                       
                    </ul>
                   
                </div>
               
            </div>
        </div>
    </section>);
}
export default Price;