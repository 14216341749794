import type { FC } from 'react';

interface TermAffiliatePageProps { }

const TermAffiliatePage: FC<TermAffiliatePageProps> = ({ }) => {
    return (
        <section className="bg-gray-50" id='page'>
            <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                <h1 className='text-4xl font-bold mt-2 mb-2'>Syarat dan Ketentuan Program Afiliasi Jaraya</h1>

                <p>Selamat datang di Program Afiliasi Jaraya. Sebelum membahas tentang Program Afiliasi Jaraya, kami ucapkan terima kasih atas kepercayaan Anda yang telah menggunakan aplikasi Jaraya. Sebagai bentuk apresiasi, kami ingin menawarkan kerjasama dengan Anda dalam Program Afiliasi Jaraya. Kami merasa senang jika Anda tertarik untuk bergabung sebagai mitra afiliasi Jaraya.</p>

                <p><strong>Program Afiliasi Jaraya</strong> adalah program yang memungkinkan individu, yang dalam hal ini adalah pengguna aplikasi Jaraya, untuk mengajak, merekomendasikan, atau mempromosikan produk dan layanan Jaraya kepada orang lain untuk menggunakan aplikasi Jaraya, dan berhak menerima komisi atas setiap pembelian/berlangganan yang dilakukan oleh pengguna yang Anda referensikan.</p>

                <p>Kami sangat berharap dapat membangun kerjasama yang saling menguntungkan dengan Anda sebagai mitra afiliasi Jaraya. Sebagai mitra afiliasi, Anda akan diberikan akses ke materi pemasaran yang telah disediakan oleh Jaraya, dan Anda pun diizinkan untuk menggunakan materi pemasaran tersebut dengan itikad baik dan sesuai dengan panduan yang diberikan oleh Jaraya.</p>

                <p>Semua hal yang mengatur hubungan antara Jaraya dan mitra afiliasi yang mendaftar dan diterima untuk mengikuti program ini akan tercantum di dalam Syarat dan Ketentuan Program Afiliasi Jaraya berikut ini.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>1. Kelayakan Menjadi Mitra Afiliasi</h2>
                <ul>
                    <li>Anda harus memiliki akun atau akses ke aplikasi Jaraya terlebih dahulu sebelum Anda dapat mempromosikan aplikasi kami.</li>
                    <li>User downline yang Anda undang belum terdaftar menjadi user kami.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>2. Tanggung Jawab Promosi</h2>
                <ul>
                    <li>Anda bertanggung jawab untuk mempromosikan aplikasi kami dengan etika yang baik dan mematuhi semua peraturan hukum positif Negara Republik Indonesia yang berlaku.</li>
                    <li>Kami berhak untuk membatalkan afiliasi Anda kapan saja jika Anda melanggar syarat dan ketentuan program afiliasi kami atau jika di kemudian hari kami menemukan praktik pemasaran yang tidak etis.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>3. Perubahan Syarat dan Ketentuan</h2>
                <ul>
                    <li>Kami berhak mengubah syarat dan ketentuan program afiliasi kami kapan saja tanpa pemberitahuan sebelumnya. Perubahan tersebut berlaku sejak saat itu dan Anda diharapkan untuk bisa mengikuti syarat dan ketentuan terbaru.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>4. Pembayaran Komisi</h2>
                <ul>
                    <li>Kami akan membayar komisi afiliasi Anda setelah pengguna yang Anda referensikan melakukan pembelian/berlangganan melalui afiliasi Anda.</li>
                    <li>Kami akan membayar komisi Anda sesuai dengan ketentuan program afiliasi kami.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>5. Keputusan dan Finalitas</h2>
                <ul>
                    <li>Setiap keputusan yang dibuat oleh kami mengenai program afiliasi kami bersifat final.</li>
                    <li>Jika Anda mendaftar ke dalam program afiliasi kami, maka Anda menyetujui untuk mematuhi semua syarat dan ketentuan yang tercantum di atas.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>6. Definisi</h2>
                <ul>
                    <li><strong>"Program"</strong> merujuk pada program afiliasi kami.</li>
                    <li><strong>"Pengguna"</strong> merujuk pada individu atau perusahaan yang mendaftar atau berlangganan aplikasi kami.</li>
                    <li><strong>"Kami"</strong> atau <strong>"penyedia layanan"</strong> merujuk pada pemilik program afiliasi atau dalam hal ini Jaraya.</li>
                    <li><strong>“Mitra Afiliasi”</strong> merujuk pada individu yang mendaftar ke penyedia layanan untuk mempromosikan atau mereferensikan aplikasi Jaraya kepada orang lain.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>7. Hak Cipta</h2>
                <ul>
                    <li>Semua hak cipta, merek dagang, dan hak kekayaan intelektual lainnya terkait dengan program afiliasi ini menjadi milik eksklusif kami.</li>
                    <li>Afiliasi tidak diperbolehkan untuk menggunakan konten program afiliasi kami tanpa izin tertulis dari kami.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>8. Pembatasan Penggunaan</h2>
                <ul>
                    <li>Mitra afiliasi tidak diperbolehkan untuk mempromosikan program afiliasi kami melalui metode yang melanggar hukum atau etika.</li>
                    <li>Mitra afiliasi tidak diperbolehkan untuk menggunakan program afiliasi kami untuk melakukan spam atau phishing.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>9. Tanggung Jawab Mitra Afiliasi</h2>
                <ul>
                    <li>Mitra afiliasi bertanggung jawab atas segala tindakan yang dilakukan melalui akun afiliasi mereka.</li>
                    <li>Mitra afiliasi bertanggung jawab untuk memastikan bahwa semua informasi yang mereka berikan dalam pendaftaran program afiliasi kami adalah benar dan akurat.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>10. Pembayaran Komisi</h2>
                <ul>
                    <li>Kami akan membayar komisi kepada mitra afiliasi setelah pengguna yang direferensikan selesai masa waktu percobaan demo aplikasi dan melakukan pembelian/berlangganan.</li>
                    <li>Komisi akan dihitung berdasarkan persentase dari total pembelian/berlangganan yang dilakukan oleh pengguna yang direferensikan.</li>
                    <li>Komisi akan terus dibayarkan kepada mitra afiliasi jika pengguna yang direferensikan melakukan pembelian/berlangganan kembali setelah masa berlakunya habis.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>11. Pembaruan Program</h2>
                <ul>
                    <li>Kami berhak untuk melakukan perubahan pada program afiliasi kami kapan saja tanpa pemberitahuan sebelumnya.</li>
                    <li>Perubahan tersebut berlaku sejak saat itu dan mitra afiliasi diharapkan untuk mengikuti syarat dan ketentuan terbaru.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>12. Pemutusan Layanan</h2>
                <ul>
                    <li>Kami berhak untuk memutuskan afiliasi kapan saja jika mitra afiliasi terbukti melanggar syarat dan ketentuan program afiliasi kami.</li>
                    <li>Kami juga berhak untuk memutuskan program afiliasi kami kapan saja dengan pemberitahuan sebelumnya.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>13. Penyelesaian Sengketa</h2>
                <ul>
                    <li>Semua sengketa yang timbul terkait dengan program afiliasi kami, akan diselesaikan melalui negosiasi yang jujur dan baik antara kedua belah pihak.</li>
                    <li>Jika negosiasi tidak berhasil, sengketa tersebut akan diselesaikan di pengadilan setempat.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>14. Komisi Berdasarkan Jumlah Pengguna</h2>
                <ul>
                    <li>Mitra afiliasi akan menerima persentase komisi yang berbeda tergantung pada jumlah pengguna yang melakukan pembelian/berlangganan aplikasi Jaraya.</li>
                    <li>Persentase komisi mitra afiliasi akan dinaikkan secara proporsional dengan jumlah pengguna yang melakukan pembelian/berlangganan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>15. Tingkat Persentase Komisi</h2>
                <ul>
                    <li>Jumlah persentase komisi yang diterima oleh mitra afiliasi akan ditentukan berdasarkan jumlah pengguna yang melakukan pembelian/berlangganan aplikasi Jaraya.</li>
                    <li>Persentase komisi akan ditetapkan sesuai dengan perjanjian antara mitra afiliasi dan penyedia layanan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>16. Perubahan Persentase Komisi</h2>
                <ul>
                    <li>Kami berhak untuk mengubah persentase komisi kapan saja dengan pemberitahuan sebelumnya.</li>
                    <li>Perubahan persentase komisi akan berlaku sejak saat itu dan mitra afiliasi diharapkan untuk bisa mengikuti perubahan tersebut.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>17. Pembayaran Komisi</h2>
                <ul>
                    <li>Pembayaran komisi akan dilakukan melalui transfer bank atau metode pembayaran yang telah disepakati bersama.</li>
                    <li>Pembayaran komisi akan dilakukan setiap bulan atau periode tertentu lainnya, seperti yang diatur dalam program komisi kami.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>18. Pemahaman dan Kepatuhan</h2>
                <ul>
                    <li>Mitra afiliasi diharapkan untuk memahami dan mematuhi semua syarat dan ketentuan program komisi kami.</li>
                    <li>Kami berhak untuk memutuskan program komisi kepada mitra afiliasi kapan saja jika mitra afiliasi terbukti melanggar syarat dan ketentuan program komisi kami.</li>
                </ul>

                <p>Dengan ini kami anggap Anda telah membaca, memahami, dan menyetujui semua Syarat dan Ketentuan yang tercantum di atas. Silakan menghubungi kami jika Anda memiliki pertanyaan tentang Syarat dan Ketentuan ini, sebelum Anda memutuskan untuk bergabung ke dalam program afiliasi Jaraya.</p>

                <p>Syarat dan Ketentuan ini berlaku sejak tanggal publikasi dan dapat diubah atau diperbarui oleh kami sewaktu-waktu tanpa pemberitahuan terlebih dahulu. Harap diingat bahwa pelanggaran atas Syarat dan Ketentuan ini dapat mengakibatkan pembatasan atau penghentian Anda ke dalam program afiliasi Jaraya.</p>

                <hr />

                <p><strong>Tanggal Berlaku:</strong> [Tanggal Publikasi]</p>
                <p><strong>Hubungi Kami:</strong></p>
                <ul>
                    <li><strong>Email:</strong> <a href="mailto:support@jaraya.com">support@jaraya.com</a></li>
                </ul>
            </div>
        </section>
    );
}
export default TermAffiliatePage;