import type { FC } from 'react';
import Hero from '../components/hero';
import Advantage from '../components/advantage';
import Main from '../components/main';
import Trusted from '../components/trusted';
import Testimony from '../components/testimony';
import Price from '../components/price';
import CallToAction from '../components/call_to_action';
import Faq from '../components/faq';

interface HomePageProps { }

const HomePage: FC<HomePageProps> = ({ }) => {
    return (
        <>
            <Hero />
            <Advantage />
            <Main />
            <Trusted />
            <Testimony />
            <Price />
            <CallToAction />
            <Faq />
        </>

    );
}
export default HomePage;