import type { FC } from 'react';

interface PrivacyPageProps { }

const PrivacyPage: FC<PrivacyPageProps> = ({ }) => {
    return (
        <section className="bg-gray-50" id='page'>
            <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                <h1 className='text-4xl font-bold mt-2 mb-2'>Kebijakan Privasi Aplikasi Jaraya</h1>
                <p><strong>Terakhir diperbarui: 30/06/2024</strong></p>
                <h2 className='text-2xl font-bold mt-2 mb-2'>Informasi yang Kami Kumpulkan</h2>
                <p>Kami dapat mengumpulkan berbagai jenis informasi pribadi dari Anda, termasuk tetapi tidak terbatas pada:</p>
                <ul>
                    <li>Informasi Identifikasi Pribadi seperti nama, alamat email, nomor telepon, dan alamat fisik.</li>
                    <li>Informasi Keuangan seperti rincian transaksi, informasi pembayaran, dan informasi keuangan lainnya yang diperlukan untuk pemrosesan transaksi.</li>
                    <li>Informasi Penggunaan Aplikasi, termasuk data penggunaan, aktivitas browsing, preferensi pengguna, dan interaksi dengan fitur aplikasi.</li>
                    <li>Informasi Teknis seperti alamat IP, jenis perangkat, sistem operasi yang digunakan, informasi peramban, serta data log lainnya.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Cara Kami Menggunakan Informasi Anda</h2>
                <p>Kami menggunakan informasi pribadi Anda untuk tujuan berikut:</p>
                <ul>
                    <li>Mengelola akun pengguna Anda dan menyediakan layanan yang Anda minta.</li>
                    <li>Mengelola transaksi dan memproses pembayaran yang Anda lakukan.</li>
                    <li>Mengirimkan informasi tentang layanan kami, pembaruan, penawaran promosi, dan pemberitahuan lain yang relevan.</li>
                    <li>Mengembangkan dan meningkatkan produk dan layanan kami berdasarkan penggunaan dan umpan balik Anda.</li>
                    <li>Mematuhi peraturan yang berlaku dan menjaga keamanan data Anda.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Berbagi Informasi</h2>
                <p>Kami tidak akan menjual, menyewakan, atau mengungkapkan informasi pribadi Anda kepada pihak ketiga kecuali dengan izin Anda atau dalam situasi-situasi tertentu seperti yang diatur dalam kebijakan ini atau diizinkan oleh hukum yang berlaku.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Perlindungan Data</h2>
                <p>Kami mengambil langkah-langkah keamanan yang memadai untuk melindungi informasi pribadi Anda dari akses yang tidak sah, penggunaan yang tidak sah, atau pengungkapan yang tidak sah. Ini termasuk penggunaan teknologi enkripsi, kontrol akses yang ketat, dan prosedur keamanan fisik dan elektronik.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Hak Anda</h2>
                <p>Anda memiliki hak untuk:</p>
                <ul>
                    <li>Mengakses dan memperbarui informasi pribadi Anda yang kami miliki.</li>
                    <li>Meminta penghapusan informasi pribadi Anda dari basis data kami, sesuai dengan kebijakan retensi data kami.</li>
                    <li>Meminta pembatasan atau penghentian penggunaan informasi pribadi Anda dalam beberapa keadaan.</li>
                </ul>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Pengaturan Privasi</h2>
                <p>Anda dapat mengatur preferensi privasi Anda dengan mengakses pengaturan akun Anda dalam aplikasi atau dengan menghubungi kami langsung untuk memperbarui atau menghapus informasi pribadi Anda.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Perubahan Kebijakan Privasi</h2>
                <p>Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu untuk mencerminkan perubahan dalam praktik kami atau perubahan hukum yang berlaku. Perubahan signifikan akan diberitahukan kepada Anda dengan cara yang sesuai, misalnya dengan memposting pemberitahuan di situs web kami atau mengirimkan pemberitahuan langsung.</p>

                <h2 className='text-2xl font-bold mt-2 mb-2'>Persetujuan Anda</h2>
                <p>Dengan menggunakan aplikasi Jaraya, Anda menyetujui pengumpulan, penggunaan, dan pengungkapan informasi Anda sesuai dengan Kebijakan Privasi ini. Jika Anda tidak setuju dengan kebijakan ini, harap tidak menggunakan aplikasi kami.</p>

                <p>Untuk pertanyaan lebih lanjut tentang Kebijakan Privasi kami atau untuk mengajukan permintaan terkait data pribadi Anda, silakan hubungi kami di <a href="mailto:support@jaraya.id">support@jaraya.id</a>.</p>
            </div>
        </section>
    );
}
export default PrivacyPage;