import type { FC } from 'react';

interface CallToActionProps { }

const CallToAction: FC<CallToActionProps> = ({ }) => {
  return (
    <section className="bg-gray-50 ">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
        <div className="max-w-screen-sm mx-auto text-center">
          <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 ">
            Mulai gunakan Jaraya Sekarang
          </h2>
          <p className="mb-6 font-light text-gray-500  md:text-lg">
            Jelajahi Fitur Premium Jaraya Tanpa Biaya Selama 1 Bulan, Mulai Sekarang!
          </p>
          <div>
            <a
              href="https://api.whatsapp.com/send/?phone=628977726030&text&type=phone_number&app_absent=0"
              className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   focus:outline-none "
              target='_blank'
            >
              Hubungi Customer Service
            </a>
            <a
              href="https://app.jaraya.id/register"
              className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2   focus:outline-none "
              target='_blank'
            >
              Coba Sekarang
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CallToAction;