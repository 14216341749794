import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Advantage from './components/advantage';
import CallToAction from './components/call_to_action';
import ComingSoon from './components/coming-soon'
import Faq from './components/faq';
import Footer from './components/footer';
import Header from './components/header';
import Hero from './components/hero';
import Main from './components/main';
import Price from './components/price';
import Testimony from './components/testimony';
import Trusted from './components/trusted';
import HomePage from './pages/home';
import PrivacyPage from './pages/privacy';
import TermPage from './pages/term';
import AboutPage from './pages/about';
import TermAffiliatePage from './pages/TermAffiliate';

function App() {
  // return (<ComingSoon />)
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/term-condition" element={<TermPage />} />
          <Route path="/affiliate" element={<TermAffiliatePage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>

  );
}

export default App;
