import type { FC } from 'react';
import { RiWhatsappLine } from "react-icons/ri";
interface HeroProps { }

const Hero: FC<HeroProps> = ({ }) => {
  return (<section className="bg-violet-100 "  >
    <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28" >
      <div className="mr-auto place-self-center lg:col-span-7" >
        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-4 md:text-lg lg:text-xl ">Mau bisnis jadi lancar? </p>
        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl ">
          Kelola Manajemen Bisnis Hanya Dalam Satu Platform.
        </h1>
        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
          Jaraya bisa membantu bisnis Anda jadi lebih optimal
        </p>
        <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
          <a
            href="https://app.jaraya.id/register"
            className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-white text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100    "
            target='_blank'
          >
            Coba Gratis
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=628977726030&text&type=phone_number&app_absent=0"
            className="text-white inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-sm font-medium bg-green-600 border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
            target='_blank'
          >
            <RiWhatsappLine />
            Tanya Dulu
          </a>
        </div>
      </div>
      <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
        <img src="/images/1st Slide.png" alt="hero image" />
      </div>
    </div>
  </section>);
}
export default Hero;